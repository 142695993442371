export const config = {
  // baseUrl: "http://invasionro.net/",
  // baseUrl: "http://localhost",
  // selfUrl: "http://194.213.16.185",
  // marketplace: "0xB4a7380A1b4561e801eBa91D016930D1408EFE4a",
  // owner: "0x4B2A262a7f0d85ECa470F0099f8f921B40134533",
  // owner: '0x252a762DFE9876D3Ed0baFA232Da94a261A1413f',
  contract_address: "0xc4478bb8ba00eede9fd56905cd95edba56f80b28",
  // contract_address: '0x799Be3b6b0c162F8E9Dd4dbB85fE7578bEF2d135',
  chainId: 4, //56  97
  rpcUrls: ["https://rinkeby.infura.io/v3/717eba768a4442d0b5f13100a72e7035"],
  BlockExplorerUrl: 'https://rinkeby.etherscan.io'
};
