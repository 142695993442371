import React, { useState, useEffect } from "react";
import "./Homepage.css";
import Countdown from "react-countdown";
import useMetaMask from "../../hooks/metamask";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import CollectionSlider from "../../Components/CollectionSlider/CollectionSlider";

import red_girl_img from '../../Static/img/red_girl_img.png';
import red_girl_img_webp from '../../Static/img/red_girl_img.png';
import author from "../../Static/img/author.png";

import presale_img from "../../Static/img/presale_img.png";
import presale_img_webp from "../../Static/img/presale_img.webp";

import opeansea from '../../Static/img/opensea.png';

// import skoly_img_webp from "../../Static/img/skoly_img.webp";
import skolyimg from "../../Static/img/skoly_img.png";

// import mobile_skoly_img_webp from "../../Static/img/mobile_skoly_img.webp";
import mobile_skoly_img from "../../Static/img/mobile_skoly_img.png";

// import roadmap_webp from "../../Static/img/roadmap.webp";
import roadmap from "../../Static/img/roadmap.png";

// import mobile_roadmap_webp from "../../Static/img/mobile-roadmap.webp";
import mobile_roadmap from "../../Static/img/mobile-roadmap.png";

import kaboom from "../../Static/img/kaboom.png";

import arrow from "../../Static/img/arrow.png";

import rapity_img from "../../Static/img/rapity_img.png";
// import rapity_img_webp from "../../Static/img/rapity_img.webp";

import team1 from "../../Static/img/team_members/team1.png";
import team2 from "../../Static/img/team_members/team2.png";
import team3 from "../../Static/img/team_members/team3.png";
import team4 from "../../Static/img/team_members/team4.png";

// import team1_webp from "../../Static/img/team_members/team1.webp";
// import team2_webp from "../../Static/img/team_members/team2.webp";
// import team3_webp from "../../Static/img/team_members/team3.webp";
// import team4_webp from "../../Static/img/team_members/team4.webp";


function Homepage(props) {
  const { getTotalSupply } = useMetaMask();
  useEffect(() => {
    getTotalSupply();
  }, [getTotalSupply])
  return (
    <div id="Homepage">
      <Section1></Section1>
      <Section2></Section2>
      <Section3></Section3>
      <Section4></Section4>
      <Section5></Section5>
      <Section6></Section6>
      <Section7></Section7>
      <Section8></Section8>
    </div>
  );
}

export default Homepage;

function Section1() {
  return (
    <section id="Section1">
      <div className="section_container py-3 py-md-5 px-0 px-md-0">
        <div className="row g-xl-0 justify-content-center">
          <div className="col-lg-6 section_1_content_col" >
            <div className="box p-3 p-md-0">
              <h1 className="text-uppercase">
                Discover Rare Collection of NFT
              </h1>
              <p>
                “Everything or nothing. Here I am, in front of me is the World
                of Art - there is no turning back and I will force myself to
                love.”
              </p>

              <div
                className="nft_box d-none d-md-block"
                style={{ marginTop: "97px" }}
              ></div>
            </div>
          </div>
          <div className="col-12 col-md-5 col-lg-6">
            <div className="col_wrapper position-relative">
              <img
                className="presale_img"
                src={presale_img}
                alt="img"
              />
              <div className="presale_starting_box" style={{ padding: 30 }}>
                {/* <MintBox /> */}
                <PresaleBox />
                {/* <AllSoldOut></AllSoldOut> */}
              </div>
            </div>
          </div>
          <div id="about_red_girl"></div>
        </div>
      </div>

      <div className="section_container py-5 px-3 px-md-0" style={{ marginTop: 120 }}>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <div className="col_wrapper" style={{ justifyContent: 'center', display: 'flex' }}>
              <img
                className="w-100"
                src={red_girl_img}
                alt="img"
              />
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2 ">
            <div className="col_wrapper">
              <h2 className="mb-3 mb-md-5 text-uppercase" id="about_red_girl" style={{ fontWeight: 600 }}>
                About RED girl
              </h2>

              <p>
                “When I started working on the RED GIRL characters, I planned
                they would all be the same. But they didn't "listen" to me
                eventually and each drawing became unique, like everyone of us.
                <br />
                <br />
                RED GIRLS have a lot in common, but each one is completely
                different. Everyone has different emotional content and external
                details. There is no complete repetition. They are similar but
                they are not clones. Everyone is unique and everyone has their
                own emotions, personality. Therefore, they are like living
                things. They seemed to come alive and brought me to them.”
              </p>

              <div
                className="user_info d-flex align-items-center"
                style={{ marginTop: "30px" }}
              >
                <img
                  src={author}
                  className="rounded-circle me-4"
                  style={{ width: "50px", height: "50px" }}
                  alt="Avatar"
                />
                <h5 className="text_red text-uppercase">skolyshev</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const PresaleBox = () => {
  // Random component
  const Completionist = () => <h2 className="text-center all-sold-out">All Sold Out</h2>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <ul className="list-unstyled align-items-center d-flex justify-content-center">
          <li className="me-3 me-md-4">
            <h2 className="mb-0">{days}</h2>
            <span>Days</span>
          </li>
          <li className="pass me-3 me-md-4">:</li>
          <li className="me-3 me-md-4">
            <h2 className="mb-0">{hours}</h2>
            <span>Hrs</span>
          </li>
          <li className="pass me-3 me-md-4">:</li>
          <li className="me-3 me-md-4">
            <h2 className="mb-0">{minutes}</h2>
            <span>Min</span>
          </li>
          <li className="pass me-3 me-md-4">:</li>

          <li className="">
            <h2 className="mb-0">{seconds}</h2>
            <span>Sec</span>
          </li>
        </ul>
      );
    }
  };
  return (
    <>
      {/* <h4 className="mb-3 mb-md-4 text-center">Pre-sale starting in</h4> */}
      {/* <Countdown date={"2023-02-01T01:02:03"} renderer={renderer} /> */}
      <h3 className="text-center" style={{ fontWeight: 500, margin: 20, fontFamily: 'turis' }}>GET YOUR <br />REDLIST NOW</h3>
      <div className="btn_box mt-2 text-center">
        {/* <button className="btn btn_hover_effect text-capitalize font-weight-bold w-100 rounded-pill bg-white text-black">

          Check Our Twitter
        </button> */}
        <a className="btn btn_hover_effect text-capitalize font-weight-bold w-100 rounded-pill bg-white text-black" href="https://twitter.com/REDNFT_" target={"_blank"} style={{fontSize: 20, fontWeight: 700}}>
          <svg
            width={50}
            height={50}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.2"
              x="0.5"
              y="0.5"
              width={43}
              height={43}
              rx="21.5"
              stroke="white"
            />
            <path
              d="M32 16.2981C31.2627 16.6254 30.4724 16.8451 29.6416 16.9449C30.4896 16.4368 31.1411 15.6327 31.4467 14.6758C30.6548 15.1449 29.7741 15.4862 28.8405 15.6701C28.0923 14.8721 27.0261 14.375 25.8462 14.375C23.5798 14.375 21.742 16.2109 21.742 18.4769C21.742 18.7979 21.7794 19.1111 21.8496 19.4119C18.4391 19.2405 15.4167 17.6072 13.3919 15.1246C13.0381 15.7324 12.837 16.4368 12.837 17.188C12.837 18.6109 13.5603 19.867 14.6623 20.6041C13.9889 20.5823 13.3561 20.3969 12.8027 20.0899C12.8027 20.107 12.8027 20.1241 12.8027 20.1413C12.8027 22.1299 14.2181 23.7865 16.0932 24.1652C15.7503 24.2587 15.3871 24.3086 15.013 24.3086C14.748 24.3086 14.4908 24.2837 14.2399 24.2338C14.7636 25.8624 16.2787 27.0515 18.0728 27.0842C16.6699 28.1845 14.9008 28.839 12.9773 28.839C12.6453 28.839 12.3195 28.8203 12 28.7813C13.8144 29.9471 15.9716 30.625 18.2895 30.625C25.8368 30.625 29.9643 24.374 29.9643 18.9537C29.9643 18.7761 29.9596 18.5969 29.9534 18.4223C30.7546 17.8441 31.4498 17.121 32 16.2981Z"
              fill="black"
            />
          </svg>
          Check Our Twitter

        </a>
      </div>
    </>
  );
};

const MintBox = () => {
  const [amountCounter, setAmountCounter] = useState(1);
  const {
    isProxy,
    ownerAddress,
    contractAddr,
    ConnectWallet,
    DisconnectWallet,
    address,
    walletAddr,
    connected,
    isLoading,
    setIsLoading,
    router,
    setRouter,
    success,
    mintAction,
    publicMintPrice,
    NFTCounter,
    totalSupply,
    maxSupply,
    setNFTCounnter,
    NFTLimit
  } = useMetaMask();

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between mb-3 mb-md-4">
        <h4 className="mb-0">Red Vamp Girl</h4>
        <h5 className="text-white-50">{totalSupply} / {maxSupply}</h5>
      </div>

      <div className="amount_counter_action">
        <div className="d-flex flex-wrap justify-content-between mb-3 mb-md-4">
          <h2>{NFTCounter * publicMintPrice} ETH</h2>

          <div className="counter_btn_wrapper d-flex align-items-center">
            <button
              className="btn m-0 p-0 rounded-circle shadow-0"
              onClick={() => setNFTCounnter(NFTCounter + (NFTCounter >= NFTLimit ? 0 : 1))}
            >
              <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  r="19.5"
                  transform="matrix(-1 0 0 1 20 20)"
                  stroke="white"
                  strokeOpacity="0.3"
                />
                <path d="M27 20H13" stroke="white" strokeWidth={2} />
                <path d="M20 27L20 13" stroke="white" strokeWidth={2} />
              </svg>
            </button>
            <b className="counter px-4 font-weight-bold text-white">
              {NFTCounter}
            </b>

            <button
              className="btn m-0 p-0 rounded-circle shadow-0"
              onClick={() => setNFTCounnter(NFTCounter - (NFTCounter > 1 ? 1 : 0))}
            >
              <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  r="19.5"
                  transform="matrix(-1 0 0 1 20 20)"
                  stroke="white"
                  strokeOpacity="0.3"
                />
                <path
                  opacity="0.5"
                  d="M27 20H13"
                  stroke="white"
                  strokeWidth={2}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="btn_box mt-2">
        <GoogleReCaptchaProvider reCaptchaKey="6Ld0gpIeAAAAAN7cy4z4iR9Oeb2TrHcwSLPc77sU">
          <button className="btn btn_hover_effect text-capitalize font-weight-bold w-100 rounded-pill bg-white text-black" onClick={mintAction}>
            Mint
          </button>
        </GoogleReCaptchaProvider>

      </div>
    </>
  );
};
const AllSoldOut = () => {
  return (
    <>
      <h4 className="mb-3 mb-md-4 text-center py-3 all-sold-out">All Sold Out</h4>

      <div className="btn_box mt-2">
        <button className="btn d-flex align-items-center justify-content-center btn_hover_effect text-capitalize font-weight-bold w-100 rounded-pill bg-white text-black opensea-text">
          <img
            className="me-2"
            src={opeansea}
            alt="img"
          />{" "}
          Buy on Opensea
        </button>
      </div>
    </>
  );
};
function Section2() {
  return (
    <section className="section_container" >

      <CollectionSlider></CollectionSlider>
    </section>
  );
}

function Section3() {
  return (
    <section id="Section3">
      <div className="section_container py-3 py-md-5 px-3 px-md-0">
        <div className="row gx-5">
          <div className="col-md-6 h-auto">
            <div className="col_wrapper d-flex flex-column justify-content-center">
              <h2 className="mb-3 mb-md-5 text-uppercase">Traits & Rarity</h2>
              <p className="family_outfit" style={{ opacity: 1 }}>
                {/* Goals and strategies in long-term */}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <p className="family_outfit" style={{ marginTop: 20 }}>
              Red girl’ NFT is a community-driven collectibles NFT project.
              ‘Red girl’ NFTs are made up with over a hundred exciting traits
              of cloths, hair, hats, body and backgrounds. Each Red Girl is a
              unique digital collectibles living on the Ethereum blockchain as
              ERC-721 tokens and hosted on IPFS.
              <br />
              <br />
              Red girl are much more than an NFT art project. We have plans
              and hopes to build a ecosystem comprised of interactivity and
              utility, community rewards and growth, collaboration with
              brands, and much more! We want the community to help dictate and
              determine which features we should be focusing on next - so the
              NFT holders will be heavily involved in the future of the Red
              girl!
            </p>

          </div>

        </div>
        <img
          className="w-100"
          src={rapity_img}
          alt="img"
        />
        <div id="Skolyshev" />
      </div>
    </section>
  );
}

function Section4() {
  return (
    <section id="Section4" style={{ marginTop: 50 }}>
      <div className="section_container" style={{ borderRadius: '0 100px 100px 0' }}>
        <div className="content p-3 p-md-5">
          <h2 className="mb-3 mb-md-5 text-uppercase"> Skolyshev</h2>

          <p>
            Skolyshev Born in Grodno, Eastern Europe. He studied painting on
            lectures and books by himself. His painting comprises of bright
            colors, bold scenes, presentation. After selecting a piece of the
            wall in your home, hang one there. After a while she will "heal"
            there, will attract the attention of guests and "make friends" with
            you. I assure you that you will not be able to dwell on one plot and
            soon will want to replenish your collection. Then you will be
            pleasantly surprised that the paintings are executed in series and
            perfectly combined with each other.
          </p>
        </div>
        <br />
        <img
          className="d-none d-md-block"
          src={skolyimg}
          alt="img"
        />
        <img
          className="d-md-none w-100"
          src={mobile_skoly_img}
          alt="img"
        />
      </div>
      <div id="roadmap_girl" />
    </section>
  );
}

function Section5() {

  return (
    <section id="Section5" style={{ marginTop: 80 }}>
      <div className="section_container py-3 py-md-5 px-3 px-lg-0">
        <div className="title_styles text-center py-3 py-md-5">
          <h2 className="mb-2" style={{ fontWeight: 600 }}>
            {" "}
            RED GIRL NFT ROADMAP
          </h2>
          <span className="text-white-100 family_outfit" style={{ color: 'white' }}>
            Goals and strategies in long-term
          </span>
        </div>
      </div>

      <div className="roadmap_box_wrapper px-3 px-md-0 d-none d-xl-flex">
        <img src={roadmap} width={"100%"} alt="roadmap" />
      </div>
      <div className="roadmap_box_wrapper px-3 px-md-0 d-xl-none">
        <img src={mobile_roadmap} width={"100%"} alt="roadmap" />
      </div>
      <div id="kaboom_lab" />
    </section>
  );
}

function Section6() {
  return (
    <section id="Section6" >
      <div className="section_container p-3 p-md-5 px-3 px-md-0" style={{ marginTop: 150, borderRadius: '0px 100px 0px 100px' }}>
        <div className="row">
          <div className="col-md-6">
            <div className="col_wrapper text-center">
              <img
                className="w-75 mx-auto"
                src={kaboom}
                alt="img"
              />
            </div>
          </div>
          <div className="col-md-6 h-auto">
            <div className="col_wrapper p-4 h-100 d-flex flex-column justify-content-center">
              <h2 className="mb-3 text-uppercase" style={{ fontWeight: 600 }}>
                About <br /> KABOOM Lab
              </h2>
              <p className="family_outfil">
                Kaboom is a WEB3 laboratory comprised professionals from art
                world to crypto sharing the same vision and coming together, to
                create dynamic, diverse, empowering digital collectible and
                thriving community. We think there is a huge gap between artists
                and crypto, physical and digital, virtual and reality. Kaboom
                lab aim to act as a bridge between them.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Section7() {
  const team_member_object = [
    {
      id: 1,
      img: team1,
      // webp: team1_webp,
      name: "Thor C",
      subName: "Crypto and Blockchain Technologist",
      description: `He’s the co-founder of AAX.com, a crypto exchange platform powered by London Stock Exchange Group Technology. He’s been investing and advising various blockchain projects in crypto trading platforms, Web3, DeFi, GameFi and NFTs.`,
    },
    {
      id: 2,
      img: team2,
      // webp: team2_webp,
      name: "Paul L",
      subName: "Veteran Crypto VC",
      description: `He’s the co-founder of Times Capital. He’s invested in more than 250+ blockchain and NFT projects since 2011. His VC firm is one of the biggest landowners in Sandbox and early investors of various notable NFT and metaverse projects such as CryptoPunks and Meebits.`,
    },
    {
      id: 3,
      img: team3,
      // webp: team3_webp,
      name: "Kim C",
      subName: "Ardent Art Collector and Curator",
      description: `He’s the co-founder of Nothing At All, an art space in Hong Kong curating projects by contemporary artists. He’s collected hundreds of valuable contemporary artwork and bluechip NFTs in the past 10 years.`,
    },
    {
      id: 4,
      img: team4,
      // webp: team4_webp,
      name: "Jimmy W",
      subName: "Serial investor and Art Curator",
      description: `Jimmy has extensive experience in investing projects from seed to pre-ipo round. He is currently the investment director of a family office, and curator for Nothing At All gallery. His drive to curate came from acutely seeing how exclusionary the art world can be for Asian collectors.`,
    },
  ];
  return (
    <section id="Section7">
      <div className="section_container py-5 px-3">
        <div className="title_styles text-center py-3 py-md-5 mb-5">
          <img src={arrow} alt="img" />
          <h2 className="mb-3 mt-4 text-uppercase"> Team Kaboom</h2>
          <span className="family_outfit font_18">
            The best professionals we are proud of
          </span>
        </div>
        <div className="body_styles">
          <div className="row g-3 g-md-5">
            {team_member_object.map((v) => {
              return (
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={v.id}>
                  <div className="col_wrapper text-center">
                    <img src={v.img} className="card_img_height" alt="img" />
                    <div className="col_content py-3">
                      <h5 className="my-2 text-uppercase">{v.name}</h5>
                      <span className="font_18 font-weight-bold text_red family_outfit d-block py-2">
                        {v.subName}
                      </span>
                      <p
                        className="family_outfit"
                        style={{ opacity: 0.7, fontWeight: 300 }}
                      >
                        {v.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div id="faq" />
        </div>
      </div>
    </section>
  );
}

function Section8() {
  return (
    <section id="Section8">
      <div className="section_container py-3 py-md-5 px-3 px-md-0">
        <h2 className="mb-3 mb-md-5">
          FAQ
        </h2>
        <div className="accordion_wrapper">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button text-uppercase font-weight-bold faq-text-question"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                What is Metamask?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  MetaMask is a software cryptocurrency wallet used to interact
                  with the Ethereum blockchain. It allows users to access their
                  Ethereum wallet through a browser extension or mobile app,
                  which can then be used to interact with decentralized
                  applications.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button text-uppercase font-weight-bold collapsed faq-text-question"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                How to mint with Metamask on a mobile phone?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  MetaMask allows you to display and transfer your NFTs/ERC-721
                  tokens directly. ... From there, you can mint, own, and manage
                  your NFTs with seamless and complete control no matter where
                  you are.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button text-uppercase font-weight-bold collapsed faq-text-question"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                How to mint with Metamask on a computer?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="faq-text-answer">
                  1. Download and install the MetaMask extension for your browser.<br />
                  2. Click on the “Get Started” button.<br />
                  3. Select “Create a Wallet” and create a password.<br />
                  4. Write down, store, or memorize your Secret Backup Phrase.<br />
                  5. Confirm your Secret Backup Phrase to ensure you have it correct.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button text-uppercase font-weight-bold collapsed faq-text-question"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                Buying NFT for the first time?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="faq-text-answer">
                  Get the Metamask Chrome or Firefox extension. Load it with ETH
                  through services that allow you to change your money to ETH
                  like Coinbase, Binance or Kraken. Then, click the button
                  "MINT" on the scary banner to connect your wallet and approve
                  the transaction on Metamask. That’s it !
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button text-uppercase font-weight-bold collapsed faq-text-question"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Where does my NFT go after I purchase?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p className="faq-text-answer">
                  Today the company is bringing NFTs to the desktop browser. ...
                  People who use Coinbase Wallet for desktop browsers will soon
                  see a new tab called “NFTs,” which will let you access the
                  non-fungible tokens (NFTs) associated with your wallet
                  address.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
