import React, { useEffect, useState } from 'react';
import loader_img from '../Static/img/loading0.gif';

export const Preloading = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeigh] = useState(window.innerHeight);

    window.onresize = (e) => {
        setWidth(window.innerWidth);
        setHeigh(window.innerHeight);
    }

    return (
        <div style={{width, height, backgroundColor: '#030303'}}>
            <img
                src={loader_img}
                alt="loader"
                className='preloader'
                width={"10%"}
            />
        </div>
    )
}