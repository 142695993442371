import logo from "./logo.svg";
import { Preloader, Placeholder } from 'react-preloading-screen';
import "./App.css";
import Base from "./Layout/Base/Base";

// ...............Pages.................
import Homepage from "./Pages/Homepage/Homepage";
import { Preloading } from "./Components/Preloader";
// ...............Pages.................End
// function App() {
//   return (
//     
//   );
// }

function App() {
  return (
    <Preloader>
      <div className="App">
        <Base>
          <Homepage></Homepage>
        </Base>
      </div>
      <Placeholder>
        <Preloading />
      </Placeholder>
    </Preloader>
  )
}

export default App;
