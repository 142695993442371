import React, { useState, useEffect, useMemo, useCallback } from "react";
import Web3Contract from 'web3-eth-contract';

import { ABI } from "./abi";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { InjectedConnector } from "@web3-react/injected-connector";
import { config } from "./config.js";
import { SnackbarProvider, useSnackbar } from 'notistack';


const web3 = new Web3(Web3.givenProvider);
const contractAddr = config.contract_address;
const Contract = new web3.eth.Contract(ABI, contractAddr);

Web3Contract.setProvider(new Web3.providers.WebsocketProvider('wss://rinkeby.infura.io/ws'));

const web3Contract = new Web3Contract(ABI, config.contract_address);



function initWeb3(provider) {
  var web3 = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });

  return web3;
}

export const injected = new InjectedConnector({
  supportedChainIds: [1, 42, 1337],
});


export const MetaMaskContext = React.createContext(null);

export const MetaMaskProvider = ({ children }) => {
  const getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "a364b3c14ec24d67a8d260b721adb45b",
        },
      },
    };
    return providerOptions;
  };
  const [isLoading, setIsLoading] = useState(false);
  const [ownerAddress, setOwnerAddress] = useState("");
  const [router, setRouter] = useState("home");
  const [connected, setConnected] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [address, setAddress] = useState("");
  const [walletAddr, setWalletAddress] = useState("");
  const [chainId, setChainId] = useState(config.chainId);
  const [networkId, setNetworkId] = useState("");
  const [web3, setWeb3] = useState(null);
  const [provider, setProvider] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);
  const [isProxy, setIsProxy] = useState(false);
  const [success, setSuccess] = useState(false);


  // status value from ERC-721 smart contract
  const [publicMintPrice, setPublicMintPrice] = useState(0.125);
  const [maxSupply, setMaxSupply] = useState(10000);
  const [totalSupply, setTotalSupply] = useState(0);
  const [whitelistMintPrice, setWhitelistMintPrice] = useState(0);
  const [mintedNFTCounter, setMintedNFTCounter] = useState(0);
  const [NFTLimit, setNFtLimit] = useState(5);
  const [NFTCounter, setNFTCounnter] = useState(1);

  const { enqueueSnackbar } = useSnackbar();

  const ConnectWallet = async () => {
    try {
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "717eba768a4442d0b5f13100a72e7035",
          },
        },
      };

      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
      });
      const provider = await web3Modal.connect();

      await subscribeProvider(provider);

      const web3 = initWeb3(provider);

      const accounts = await web3.eth.getAccounts();

      const address = accounts[0];

      const networkId = await web3.eth.net.getId();

      const chainId = await web3.eth.chainId();

      console.log("chain id: " + chainId);
      await setWeb3(web3);
      await setProvider(provider);
      await setConnected(true);
      await setAddress(address);
      await setWalletAddress(
        address.substring(0, 4) +
        "..." +
        address.substring(address.length - 6, address.length)
      );
      await setChainId(chainId);
      await setNetworkId(networkId);
      await checkNetwork();
      await setWeb3Modal(web3Modal);
      // alert(modalShow);

      console.log("address: " + address);
    } catch (error) {
      console.log(error);
    }
  };

  const setupNetwork = async () => {
    const provider = window.ethereum
    if (provider) {
      const chainId = parseInt(3, 10)
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: 'Rinkeby Test Network',
              nativeCurrency: {
                name: 'ETH',
                symbol: 'eth',
                decimals: 18,
              },
              rpcUrls: [config.rpcUrls],
              blockExplorerUrls: [`${config.BlockExplorerUrl}/`],
            },
          ],
        })
        return true
      } catch (error) {
        console.error('Failed to setup the network in Metamask:', error)
        return false
      }
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  }

  const DisconnectWallet = async () => {
    try {
      if (web3 && web3.currentProvider && web3.currentProvider.close) {
        await web3.currentProvider.close();
      }

      await web3Modal.clearCachedProvider();
      setWeb3Modal(new Web3Modal());

      await setConnected(false);
      setAddress("");
      setWalletAddress("");
      console.log(connected);
    } catch (error) {
      console.log(error);
    }
    // this.setState({ ...INITIAL_STATE });
  };

  async function subscribeProvider(provider) {
    if (!provider.on) {
      return;
    }
    provider.on("disconnect", () => DisconnectWallet());

    provider.on("accountsChanged", async (accounts) => {
      await setAddress(accounts[0]);
      // await this.getAccountAssets();
    });
  }

  const checkNetwork = async () => {
    if (chainId === config.chainId) await setModalShow(false);
    else await setModalShow(true);
    // await this.getAccountAssets();
  };

  const mintAction = async () => {
    if (connected === true) {
      const networkId = await web3.eth.net.getId();
      if (networkId !== config.chainId) {
        //56   97
        enqueueSnackbar("Please, select Rinkeby network!", { variant: "error", anchorOrigin: { vertical: 'top', horizontal: "left" } });
        return;
      }

      setIsLoading(true);

      try {
        let tx = await Contract.methods
          .mint(address, NFTCounter)
          .send({ from: address, to: contractAddr, value: Math.pow(10, 18) * publicMintPrice * NFTCounter });
        setIsLoading(false);
        enqueueSnackbar("Minted!");
        setSuccess(true);

      } catch (error) {
        console.log(error.message);
        enqueueSnackbar(error.message)
        setIsLoading(false);
      }
    } else {
      enqueueSnackbar("Please connect to the wallet", { variant: "error", anchorOrigin: { vertical: 'top', horizontal: "left" } });
      ConnectWallet();
    }
  };

  const getTotalSupply = async () => {
    let tx = await web3Contract.methods.totalSupply().call();
    setTotalSupply(tx);
    console.log(tx);
  }

  const values = {
    isProxy,
    ownerAddress,
    contractAddr,
    ConnectWallet,
    DisconnectWallet,
    address,
    walletAddr,
    connected,
    isLoading,
    setIsLoading,
    router,
    setRouter,
    success,
    mintAction,
    publicMintPrice,
    NFTCounter,
    totalSupply,
    maxSupply,
    setNFTCounnter,
    NFTLimit,
    getTotalSupply
  };

  return (
    <MetaMaskContext.Provider value={values}>
      {children}
    </MetaMaskContext.Provider>
  );
};

export default function useMetaMask() {
  const context = React.useContext(MetaMaskContext);

  if (context === undefined) {
    throw new Error(
      "useMetaMask hook must be used with a MetaMaskProvider component"
    );
  }

  return context;
}
