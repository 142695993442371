import React from 'react';
import ReactDOM from 'react-dom';
import { Preloader, Placeholder } from 'react-preloading-screen';
import App from './App';
import { Preloading } from './Components/Preloader';
import { MetaMaskProvider } from "./hooks/metamask";
import { Web3ReactProvider } from "@web3-react/core";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Web3 from "web3";

function getLibrary(provider, connector) {
  return new Web3(provider);
}

const styles = {
  success: { backgroundColor: 'purple' },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'green' },
  info: { backgroundColor: 'yellow' },
};

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={4} classes={styles}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetaMaskProvider>
          <App />
        </MetaMaskProvider>
      </Web3ReactProvider>
    </SnackbarProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals